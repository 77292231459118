import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useAppointment } from "../../hooks/useAppointment";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { useRedirect } from "../../hooks/useRedirect";
import { RootState } from "../../store";
import { overviewAppointmentSliceActions } from "../../store/overview-appointment-slice";
import { Appointment } from "../../types/appointment";
import { AppointmentCard } from "../AppointmentCard/AppointmentCard";
import { DeficiencyCard } from "../DeficiencyCard/DeficiencyCard";
import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";
import { NoData } from "../NoData/NoData";

import styles from "./last-services.module.css";

export const LastServices = () => {
  const scrollableWrapper = document.getElementById(
    "scrollable-wrapper"
  ) as HTMLElement;
  const { setDashboardUrlAndRedirect } = useRedirect();
  const dispatch = useDispatch();

  const isViewOnly = useSelector((state: RootState) => state.property.viewOnly);

  const propertyIdFromProperty = useSelector(
    (state: RootState) => state.property.id
  );
  const propertyIdFromAppointment = useSelector(
    (state: RootState) => state.appointment.propertyId
  );

  const propertyId = isViewOnly
    ? propertyIdFromProperty
    : propertyIdFromAppointment;

  const selectedPropertyId = useSelector(
    (state: RootState) => state.user.selectedPropertyId
  );

  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");

  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState({
    "page[size]": 20,
    "page[number]": 1,
    "filter[untilDate]": tomorrow,
    sort: "-plannedStart",
    "filter[propertyId]": isCustomerAdmin ? selectedPropertyId : propertyId,
    include: "documents,incidents.propertyArea",
  });

  const { appointments, appointmentsMeta, loading } = useAppointment(
    queryParams,
    true,
    true
  );

  const [isFetching, setIsFetching] = useInfiniteScroll(
    scrollableWrapper,
    hasMore
  );

  const handleServiceClicked = (appointment: Appointment) => {
    const isDeficiency = appointment.incidents.length > 0;

    dispatch(overviewAppointmentSliceActions.setAppointment(appointment));

    const url = isDeficiency
      ? `/deficiencies/${appointment.incidents[0].id}/overview/readonly`
      : `/services/${appointment.id}/services-overview/readonly`;
    setDashboardUrlAndRedirect(url);
  };

  useEffect(() => {
    if (isFetching) {
      setQueryParams({
        ...queryParams,
        "page[number]": queryParams["page[number]"] + 1,
      });
      setIsFetching(false);
    }
  }, [queryParams, selectedPropertyId, isFetching]);

  useEffect(() => {
    if (appointmentsMeta) {
      setHasMore(appointmentsMeta?.currentPage < appointmentsMeta?.totalPages);
    }
  }, [appointmentsMeta]);

  useEffect(() => {
    setQueryParams({
      ...queryParams,
      "filter[propertyId]": isCustomerAdmin ? selectedPropertyId : propertyId,
      "page[number]": 1,
    });
  }, [selectedPropertyId, isCustomerAdmin]);

  const renderAppointments = () => {
    if (loading && queryParams["page[number]"] === 1) {
      return <LoadingSpinner loading={loading} />;
    }

    if (appointments.length > 0) {
      return (
        <>
          {appointments
            .filter(
              (appointment) => appointment.appointmentState === "completed"
            )
            .map((appointment) =>
              appointment.incidents.length === 0 ? (
                <AppointmentCard
                  key={appointment.id}
                  upcoming={false}
                  appointment={appointment}
                  onClick={() => handleServiceClicked(appointment)}
                  color="blue"
                />
              ) : (
                <DeficiencyCard
                  key={appointment.id}
                  deficiency={appointment.incidents[0]}
                  propertyAreaLabel={
                    appointment?.incident?.propertyArea &&
                    appointment?.incident?.propertyArea[0]?.label
                  }
                  onClick={() => handleServiceClicked(appointment)}
                />
              )
            )}
          {loading && queryParams["page[number]"] > 1 && (
            <LoadingSpinner loading={loading} size={20} top="0" />
          )}
        </>
      );
    }

    return <NoData>{t("NoServices")}</NoData>;
  };

  return <div className={styles["services-block"]}>{renderAppointments()}</div>;
};
