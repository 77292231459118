import adjustment from "./adjustment_24px.png";
import infestation from "./infestation_24px.png";
import maintenance from "./maintenance_24px.png";
import noInfestation from "./noInfestation_24px.png";
import unreachable from "./unreachable_24px.png";

type ServiceIconProps = {
  service: "infestation" | "noInfestation" | "maintenance" | "unreachable" | "adjustment";
};

const SERVICE_IMAGES: Record<
  "infestation" | "noInfestation" | "maintenance" | "unreachable" | "adjustment",
  string
> = {
  infestation: infestation,
  noInfestation: noInfestation,
  maintenance: maintenance,
  unreachable: unreachable,
  adjustment: adjustment
};

export const ServiceIcon = ({ service }: ServiceIconProps) => (
  <img src={SERVICE_IMAGES[service]} alt="Service icon" />
);
