import { Monitor } from "../types/monitor";
import { PropertyArea } from "../types/property";

/* Extract the function where we map property areas to store only data that we need,
so that we minimize code duplication */
export const mapPropertyAreas = (data: any) =>
  data.propertyAreas
    .map((propertyArea: PropertyArea) => ({
      map: propertyArea.map,
      propertyArea: propertyArea.label,
      propertyAreaId: propertyArea.id,
      monitors: propertyArea.includes.monitors.map((monitor: Monitor) => ({
        id: monitor.id,
        isActive: monitor.isActive,
        monitorType: monitor.monitorType.label,
        monitorTypeId: monitor.monitorTypeId,
        position: monitor.position,
        location: monitor.location,
        identificationNumber: monitor.identificationNumber,
        x: monitor.x,
        y: monitor.y
      })),
    }))
    .filter(
      (item: { propertyArea: string; monitors: Monitor[] }) =>
        item.monitors.length > 0
    );

export const mapPropertyAreasWithMonitors = (
  propertyAreas: any,
  monitorsData: any
) => {
  return propertyAreas
    .map((propertyArea: PropertyArea, index: number) => ({
      map: propertyArea.map,
      propertyArea: propertyArea.label,
      propertyAreaId: propertyArea.id,
      monitors: monitorsData[index].map((monitor: Monitor) => ({
        id: monitor.id,
        isActive: monitor.isActive,
        monitorType: monitor.monitorType.label,
        monitorTypeId: monitor.monitorTypeId,
        position: monitor.position,
        location: monitor.location,
        identificationNumber: monitor.identificationNumber,
        x: monitor.x,
        y: monitor.y,
        createdAt: monitor.createdAt,
      })),
    }))
    .filter((item: any) => item.monitors.length > 0);
};
