import { t } from "i18next";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import PropertyService from "../../api/PropertyService";
import { AppointmentDetails } from "../../components/AppointmentDetails/AppointmentDetails";
import { BackButtonHeader } from "../../components/BackButtonHeader/BackButtonHeader";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomInput } from "../../components/CustomInput/CustomInput";
import { CustomTextArea } from "../../components/CustomTextArea/CustomTextArea";
import { FloatingButtons } from "../../components/FloatingButtons/FloatingButtons";
import FullScreenPreview from "../../components/FullScreenPreview/FullScreenPreview";
import { KundeForm } from "../../components/KundeForm/KundeForm";
import { LoadingSpinner } from "../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../components/Modal/Modal";
import { NoData } from "../../components/NoData/NoData";
import PinCropMap from "../../components/PinCropMap/PinCropMap";
import { SBKForm } from "../../components/SBKForm/SBKForm";
import { SubMenu } from "../../components/SubMenu/SubMenu";
import { TabTypeSelection } from "../../components/TabTypeSelection/TabTypeSelection";
import { useCreateDeficiency } from "../../hooks/useCreateDeficiency";
import { useScreenSize } from "../../hooks/useScreenSize";
import { AppLayout } from "../../layout/AppLayout/AppLayout";
import { RootState } from "../../store";
import { creatingDeficiencyActions } from "../../store/deficiencies-slice";
import { activeTabsActions } from "../../store/tabs-slice";
import { techniciansSliceActions } from "../../store/technicians-slice";
import styles from "../../styles/create-deficiency.module.css";
import { Document } from "../../types/document";
import { uploadMap } from "../../utils/uploadMap";

type ModalProps = {
  isOpen: boolean;
  deficiencyType: string;
};

export const CreateDeficiency = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isDesktop } = useScreenSize();

  const deficiency = useSelector((state: RootState) => state.deficiency);
  const technicians = useSelector((state: RootState) => state.technicians);
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );
  const deficiencyPosition = useSelector(
    (state: RootState) => state.deficiency.deficiencyPosition
  );

  const [map, setMap] = useState<Document | null>(null);
  const [deficiencyId] = useState("");
  const [deficiencyType, setDeficiencyType] = useState("SBK");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [modal, setModal] = useState<ModalProps>({
    isOpen: false,
    deficiencyType: "",
  });

  const [imageLoading, setImageLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { handleCreateDeficiency, loading } = useCreateDeficiency();

  useEffect(() => {
    dispatch(activeTabsActions.setDashbaordUrl(location.pathname));
  }, [dispatch, location.pathname]);

  // Set deficiency type
  useEffect(() => {
    if (deficiency.responsibility) {
      const responsibility =
        deficiency.responsibility === "exterminator" ? "SBK" : "Kunde";
      setDeficiencyType(responsibility);
    }
  }, [deficiency.responsibility]);

  useEffect(() => {
    if (deficiency.responsibility === "customer" || technicians.length > 0) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [deficiency.responsibility, technicians.length]);

  // Fetch property area to set map
  useEffect(() => {
    setMapLoading(true);
    PropertyService.GetPropertyArea(deficiency.propertyAreaId)
      .then((propertyArea: any) => {
        setMap(propertyArea.data.map);
        setMapLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setMapLoading(false);
      });
  }, [deficiency.propertyAreaId]);

  const handleMapUpload = () => {
    // If floor is created we can add a map, otherwise not
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleLoadingChange = (loading: boolean) => {
    setImageLoading(loading);
  };

  // Event handler for file input change
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const data = { id: deficiency.propertyAreaId };
    uploadMap(event, data, setMap);
  };

  const handleLocationChange = (location: string) => {
    dispatch(creatingDeficiencyActions.setLocation(location));
  };

  const handleBackButtonClicked = () => {
    // Handle back button click here
    navigate("/deficiencies/create/step-2");
  };

  const onChangeTab = (type: string) => {
    // This might need some refactoring, it seems overly complicated.
    const shouldOpenModal =
      (deficiency.responsibility === "exterminator" && type === "Kunde") ||
      (deficiency.responsibility === "customer" && type === "SBK");

    if (shouldOpenModal) {
      setModal({ isOpen: true, deficiencyType: type });
    }
  };

  const handleChangeTab = () => {
    // // Set responsibility
    dispatch(
      creatingDeficiencyActions.setResponsibility(
        modal.deficiencyType === "SBK" ? "exterminator" : "customer"
      )
    );
    // Reset responsibility related fields
    dispatch(creatingDeficiencyActions.responsibilityReset());
    dispatch(techniciansSliceActions.resetState());

    setModal({
      isOpen: false,
      deficiencyType: "",
    });
  };

  const handleTextareaChange = (comment: string) => {
    dispatch(creatingDeficiencyActions.setComment(comment));
  };

  const handleCrop = (crop: { x: number; y: number }) => {
    dispatch(
      creatingDeficiencyActions.setDeficiencyPosition({ x: crop.x, y: crop.y })
    );
  };

  const deficiencyPinAbbr = useMemo(
    () => ({
      name: deficiency.deficiencySubtype.name.slice(0, 3).toUpperCase(),
      number: "001",
    }),
    [deficiency.deficiencySubtype]
  );

  useEffect(() => {
    if (isCustomerAdmin) {
      dispatch(creatingDeficiencyActions.setResponsibility("customer"));
    }
  }, [isCustomerAdmin]);

  return (
    <AppLayout hideHeader={!isCustomerAdmin || (isCustomerAdmin && !isDesktop)}>
      <div>
        <BackButtonHeader
          handleBackButtonClicked={handleBackButtonClicked}
          isDeficiency
        />
        <div className={styles["content-wrapper"]}>
          {(!isCustomerAdmin || (isCustomerAdmin && !isDesktop)) && (
            <AppointmentDetails />
          )}

          <div className={styles["content"]}>
            <SubMenu showSearchButton={false}>
              <span>{`${deficiency.deficiencySubtype.name}`}</span>
            </SubMenu>

            <Modal
              message={t("ChangeResponsibility")}
              isOpen={modal.isOpen}
              onCancel={() =>
                setModal({
                  isOpen: false,
                  deficiencyType: "",
                })
              }
              onConfirm={handleChangeTab}
            />

            {mapLoading && (
              <LoadingSpinner loading={mapLoading} size={60} top="0" />
            )}

            {map && (
              <div className={styles["image"]}>
                <PinCropMap
                  map={map}
                  crop={deficiencyPosition}
                  onCropChange={handleCrop}
                  monitorPinAbbr={deficiencyPinAbbr}
                  isDraggable={false}
                  zoom={false}
                />
                <FullScreenPreview>
                  <PinCropMap
                    map={map}
                    crop={deficiencyPosition}
                    onCropChange={handleCrop}
                    monitorPinAbbr={deficiencyPinAbbr}
                  />
                </FullScreenPreview>
              </div>
            )}

            {!map && !mapLoading && (
              <>
                <NoData onClick={handleMapUpload}>Karte Hinzufügen</NoData>

                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                />
              </>
            )}

            <CustomInput
              label="Standortinformation"
              placeholder="Kein Standort eingetragen"
              onInputChange={handleLocationChange}
              onBlur={() => {}}
              initialValue={deficiency.location}
              maxLength={60}
            />

            {!isCustomerAdmin && (
              <TabTypeSelection
                label="Zuständigkeit"
                tabs={["SBK", "Kunde"]}
                activeTab={deficiencyType}
                changeActiveTab={onChangeTab}
              />
            )}

            {deficiencyType === "SBK" ? (
              <SBKForm
                deficiencyId={deficiencyId}
                onLoadingChange={handleLoadingChange}
              />
            ) : (
              <KundeForm
                deficiencyId={deficiencyId}
                onLoadingChange={handleLoadingChange}
              />
            )}

            <CustomTextArea
              placeholder="Zusätzlicher Text"
              onTextareaChange={handleTextareaChange}
              defaultValue={deficiency.comment}
            />
          </div>
        </div>
      </div>
      <FloatingButtons>
        <CustomButton
          disabled={isButtonDisabled || imageLoading}
          loading={loading}
          color="green"
          onClick={handleCreateDeficiency}
        >
          {t("Confirm")}
        </CustomButton>
      </FloatingButtons>
    </AppLayout>
  );
};
