import { createSlice } from "@reduxjs/toolkit";

export interface OldMonitor {
  id: string;
  position: string;
  location: string;
  appointmentId: string;
  createdAt: string;
}

export type OldMonitorsSliceState = {
  oldMonitors: OldMonitor[];
};

const initialState: OldMonitorsSliceState = {
  oldMonitors: [],
};

export const oldMonitorsSlice = createSlice({
  name: "oldMonitors",
  initialState,
  reducers: {
    setOldMonitors: (state, action: { payload: OldMonitor }) => {
      state.oldMonitors.push(action.payload);
    },
    removeMonitorsByAppointment: (state, action: { payload: string }) => {
      state.oldMonitors = state.oldMonitors.filter(
        (monitor) => monitor.appointmentId !== action.payload
      );
    },
    resetState(state: OldMonitorsSliceState) {
      return { ...initialState };
    },
  },
});

export const oldMonitorsActions = oldMonitorsSlice.actions;

export default oldMonitorsSlice.reducer;
