import {
  Appointment,
  AppointmentStorePayload,
  AppointmentUpdateRequestPayload,
  MonitorDetailsUpdateRequestPayload,
} from "../types/appointment";
import { Deficiency } from "../types/deficiencies";
import { Document } from "../types/document";
import { ResponseMeta, ResponseWarningQueries } from "../types/response";

import ApiService from "./ApiService";

export interface AppointmentQueryParams {
  "page[size]"?: number;
  "page[number]"?: number;
  sort?: string;
  include?: string;
  "filter[sinceDate]"?: string;
  "filter[untilDate]"?: string;
  "filter[propertyId]"?: string;
}

interface AppointmentInclude {
  documents?: Document[];
  incidents?: Deficiency[];
}

const AppointmentService = {
  GetAppointments: async <
    T extends {
      data: Appointment[];
      includes: AppointmentInclude;
    } & ResponseMeta &
      ResponseWarningQueries
  >(
    params: AppointmentQueryParams
  ): Promise<T> => {
    return ApiService.Get("appointments", { ...params });
  },

  GetAppointment: async (
    appointmentId: string
  ): Promise<{ data: Appointment } & ResponseMeta & ResponseWarningQueries> => {
    return ApiService.Get(`appointments/${appointmentId}`);
  },

  CreateAppointment: async (
    data: AppointmentStorePayload
  ): Promise<{ data: Appointment } & ResponseMeta & ResponseWarningQueries> => {
    return ApiService.Post("appointments", data);
  },

  UpdateAppointment: async (
    appointmentId: string,
    data: AppointmentUpdateRequestPayload
  ): Promise<{ data: Appointment } & ResponseMeta & ResponseWarningQueries> => {
    return ApiService.Update(appointmentId, "appointments", data);
  },

  DeleteAppointment: async (appointmentId: string): Promise<object> => {
    return ApiService.Delete(appointmentId, "appointments");
  },

  UpdateMonitorDetails: async (
    appointmentId: string,
    data: MonitorDetailsUpdateRequestPayload
  ): Promise<object> => {
    return ApiService.Update(
      "",
      `appointments/${appointmentId}/update-monitor-details`,
      data
    );
  },

  GetMonitorsWithVersions: async (
    appointmentId: string,
    propertyAreaId: string
  ): Promise<{ data: any } & ResponseMeta & ResponseWarningQueries> => {
    return ApiService.Get(
      `appointments/${appointmentId}/property-areas/${propertyAreaId}/monitors-with-versions`
    );
  },

  GetAdjustedMonitors: async (
    appointmentId: string
  ): Promise<{ data: any } & ResponseMeta & ResponseWarningQueries> => {
    return ApiService.Get(`appointments/${appointmentId}/adjusted-monitors`);
  },
};

export default AppointmentService;
