import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PropertyService from "../../../api/PropertyService";
import { LoadingSpinner } from "../../../components/LoadingSpinner/LoadingSpinner";
import { NoData } from "../../../components/NoData/NoData";
import PinCropMap from "../../../components/PinCropMap/PinCropMap";
import { SubMenuButton } from "../../../components/SubMenuButton/SubMenuButton";
import { BugIcon } from "../../../icons/BugIcon/BugIcon";
import { AppLayout } from "../../../layout/AppLayout/AppLayout";
import { RootState } from "../../../store";
import styles from "../../../styles/customer-monitors.module.css";
import { Document } from "../../../types/document";
import { Crop } from "../../../types/map";
import { MonitorPinAbbr } from "../../../types/map";

interface PropertyArea {
  id: string;
  name: string;
  map: Document | null;
  monitors: any[];
}

export const Monitors = () => {
  const [propertyAreas, setPropertyAreas] = useState<any[]>([]);
  const [subMenuButtons, setSubMenuButtons] = useState<string[]>([]);
  const [map, setMap] = useState<Document | null>(null);
  const [crops, setCrops] = useState<Crop[]>([]);
  const [monitorPinAbbrs, setMonitorPinAbbrs] = useState<MonitorPinAbbr[]>([]);
  const [currentTab, setCurrentTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const selectedProperty = useSelector(
    (state: RootState) => state.user?.selectedProperty?.id
  );

  const getProperties = async () => {
    setLoading(true);
    setMessage("");
    await PropertyService.GetProperty(selectedProperty)
      .then((property) => {
        const areas = property?.data?.propertyAreas?.map((propertyArea) => ({
          id: propertyArea.id,
          name: propertyArea.label,
          map: propertyArea.map,
          monitors: propertyArea.includes.monitors,
        }));

        setPropertyAreas(areas);
        if (areas.length === 0) {
          setMessage("Für dieses Objekt gibt es keine Bereiche");
        } else {
          const dynamicButtons = areas.map((area) => area.name);
          setSubMenuButtons(dynamicButtons);
          setCurrentTab(areas[0].name);
          changeMap(0, areas);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const changeMap = (i: number, areas: PropertyArea[] | null = null) => {
    const propertyAreasToUse = areas || propertyAreas;

    setLoading(true);
    setMessage("");

    if (!propertyAreasToUse[i]?.map) {
      setMessage("Keine Karte hochgeladen");
    } else {
      setMap(propertyAreasToUse[i].map);
      if (propertyAreasToUse[i].monitors.length > 0) {
        const mappedCrops = propertyAreasToUse[i].monitors.map(
          (monitor: any) => ({
            x: Number(monitor.x),
            y: Number(monitor.y),
          })
        );

        const mappedMonitorPinAbbrs = propertyAreasToUse[i].monitors.map(
          (monitor: any) => ({
            name: monitor.referenceNumber.slice(0, 3).toUpperCase(),
            number: monitor.position.toString(),
          })
        );

        setCrops(mappedCrops);
        setMonitorPinAbbrs(mappedMonitorPinAbbrs);
      } else {
        setCrops([{ x: 1, y: 1 }]);
        setMonitorPinAbbrs([{ name: "", number: "" }]);
      }
    }
    setLoading(false);
  };

  const handleChangeTab = (tab: any, i: any) => {
    setCurrentTab(tab);
    setMap(null);
    setCrops([]);
    setMonitorPinAbbrs([]);
    setTimeout(() => {
      changeMap(i);
    }, 0);
  };

  useEffect(() => {
    getProperties();
  }, [selectedProperty]);

  return (
    <AppLayout>
      <div className={styles["customer-monitors"]}>
        <div className={styles["customer-monitors__heading"]}>
          <p className={styles["customer-monitors__title"]}>
            <BugIcon /> Monitore
          </p>
        </div>

        <div className={styles["tabs"]}>
          <div className={styles["buttons"]}>
            {subMenuButtons.length > 0 &&
              subMenuButtons.map((button, i) => (
                <SubMenuButton
                  currentTab={currentTab}
                  value={button}
                  onSetTab={() => handleChangeTab(button, i)}
                  key={i}
                />
              ))}
          </div>
        </div>
      </div>
      {!loading &&
        map &&
        crops.length > 0 &&
        monitorPinAbbrs.length > 0 &&
        message === "" && (
          <div className={styles["image-container"]}>
            <div className={styles["image"]}>
              <PinCropMap
                map={map}
                crop={crops}
                monitorPinAbbr={monitorPinAbbrs}
                onCropChange={() => {}}
                isDraggable={true}
              />
            </div>
          </div>
        )}
      {loading && <LoadingSpinner loading={loading} size={30} />}
      {message !== "" && (
        <NoData>
          <p>{message}</p>
        </NoData>
      )}
    </AppLayout>
  );
};
