import { ServiceIcon } from "../../icons/ServiceIcon/ServiceIcon";

import styles from "./service-count.module.css";

interface Props {
  count: number;
  service: "infestation" | "noInfestation" | "maintenance" | "unreachable" | "adjustment";
  addInfoIcon?: boolean;
}

const SERVICE_COUNT_NUMBER_COLOR: Record<
  "infestation" | "noInfestation" | "maintenance" | "unreachable" | "adjustment",
  string
> = {
  infestation: styles["service__count__number--red"],
  noInfestation: styles["service__count__number--green"],
  maintenance: styles["service__count__number--gray"],
  unreachable: styles["service__count__number--orange"],
  adjustment: styles["service__count__number--gray"],
};

export const ServiceCount = ({
  count,
  service,
  addInfoIcon = false,
}: Props) => {
  const countNumberColor = SERVICE_COUNT_NUMBER_COLOR[service];
  return (
    <div className={styles["service__count"]}>
      <span
        className={`${styles["service__count__number"]} ${countNumberColor}`}
      >
        {count}
      </span>
      <ServiceIcon service={service} />
      {addInfoIcon && <img src="/img/info-icon.png" />}
    </div>
  );
};
