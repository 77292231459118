import { ServiceCount } from "../ServiceCount/ServiceCount";

import styles from "./card-wrapper.module.css";

interface Props {
  title: any;
  children: any;
  collapsed?: boolean;
  color: "white" | "gray";
  service?: "infestation" | "noInfestation" | "maintenance" | "unreachable" | "adjustment";
  addInfoIcon?: boolean;
  infestationCount?: number;
  onClick?: () => void;
}

const CARD_WRAPPER_TITLE_BACKGROUND: Record<"white" | "gray", string> = {
  white: styles["card__wrapper__content--white"],
  gray: styles["card__wrapper__content--gray"],
};

export const CardWrapper = ({
  title,
  children,
  collapsed = false,
  color,
  service,
  infestationCount,
  onClick,
  addInfoIcon,
}: Props) => {
  return (
    <div className={styles["card__wrapper"]}>
      <div
        className={`${styles["card__wrapper__content"]} ${
          CARD_WRAPPER_TITLE_BACKGROUND[color]
        } ${!collapsed && styles["monitors__wrapper__content--shadow"]}`}
      >
        <div
          className={`${styles["card__wrapper__content--title"]} ${
            color === "gray" && styles["card__wrapper__content--title--gray"]
          }`}
          onClick={onClick}
        >
          <div className={styles["card__wrapper__content--title-text"]}>
            {title}
          </div>
          {service && (
            <ServiceCount
              count={infestationCount ?? 0}
              service={service}
              addInfoIcon={addInfoIcon ?? true}
            />
          )}
        </div>
      </div>
      {
        <ul
          className={`${styles["card__wrapper__list"]} ${
            color === "gray" && styles["card__wrapper__list--gray"]
          }`}
        >
          {children}
        </ul>
      }
    </div>
  );
};
