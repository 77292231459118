import axios from "axios";

import config from "../config/api.config";

interface QueryParams {
  [key: string]: string | number;
}

export type ApiError = {
  response: {
    config: unknown;
    data: {
      message: string;
    };
    headers: unknown;
    request: unknown;
    status: number;
    statusText: string;
  };
};

export type Error = {
  status: number;
  error: string;
};

const mapQueryParamsToUrl = (qp: QueryParams): Array<string> => {
  return Object.keys(qp).map((key: string) => {
    return `${key}=${qp[key]}`;
  });
};

const correctFormatForQueryUrl = (qp: QueryParams): string => {
  if (qp === null) {
    return "";
  }
  const qpAsStr = mapQueryParamsToUrl(qp);
  const returnValue = qpAsStr.length === 0 ? "" : `?${qpAsStr.join("&")}`;
  return returnValue;
};

const handleError = (error: ApiError): Error => {
  if (error?.response) {
    return {
      status: error.response.status,
      error: error.response.data.message,
    };
  } else {
    return { status: 520, error: "An unknown error happened" };
  }
};

const ApiService = {
  Get: async <T extends object>(
    route: string,
    qp: QueryParams = {}
  ): Promise<T> => {
    const queryString = correctFormatForQueryUrl(qp);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(
          `${config.apiUrl}/api/${route}${queryString}`
        );
        resolve(result.data);
        return result.data;
      } catch (error: any) {
        reject(handleError(error));
      }
    });
  },
  GetById: async <T extends object>(
    id: number | string,
    route: string
  ): Promise<T> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.get(`${config.apiUrl}/api/${route}/${id}`);
        resolve(result.data);
        return result.data;
      } catch (error: any) {
        reject(handleError(error));
      }
    });
  },
  Post: async <T extends object>(route: string, data: any): Promise<T> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(`${config.apiUrl}/api/${route}`, data);
        resolve(result.data);
        return result.data;
      } catch (error: any) {
        reject(handleError(error));
      }
    });
  },
  Update: async <T extends object>(
    id: string | null = null,
    route: string,
    data: any
  ): Promise<T> => {
    return new Promise(async (resolve, reject) => {
      try {
        const cleanedRoute = route.startsWith('/') ? route.substring(1) : route;
        const fullRoute = id ? `${cleanedRoute}/${id}` : cleanedRoute;
        const result = await axios.put(
          `${config.apiUrl}/api/${fullRoute}`,
          data
        );
        resolve(result.data);
        return result.data;
      } catch (error: any) {
        reject(handleError(error));
      }
    });
  },
  Delete: async <T extends object>(id: string, route: string): Promise<T> => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await axios.delete(
          `${config.apiUrl}/api/${route}/${id}`
        );
        resolve(result.data);
        return result.data;
      } catch (error: any) {
        reject(handleError(error));
      }
    });
  },
};

export default ApiService;
