import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";

import config from "../../config/api.config";
import {
  DocumentationSliceState,
  PropertyArea,
  PropertyAreaAdjustedMonitors,
  PropertyAreaDeficiencies,
  Technician,
} from "../../store/documentation-slice";
import { Deficiency } from "../../types/deficiencies";
import { MinimalProduct } from "../../types/product";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    paddingTop: 100,
    paddingBottom: 80,
  },
  normalPage: {
    flexDirection: "column",
    padding: 20,
    paddingBottom: 80,
  },
  title: {
    fontSize: 12,
    flexDirection: "column",
    display: "flex",
    marginBottom: 5,
    color: "#8493AB",
  },
  name: {
    flexDirection: "column",
    display: "flex",
    marginBottom: 15,
    marginTop: 10,
    color: "#3d5170",
    fontWeight: 1000,
    fontSize: 18,
  },
  documentName: {
    color: "#3d5170",
    fontWeight: "bold",
    fontSize: 32,
    marginTop: 30,
    marginBottom: 30,
  },
  text: {
    flexDirection: "column",
    display: "flex",
    marginBottom: 5,
    marginTop: 5,
    color: "#3d5170",
    fontSize: 15,
    minWidth: 30,
  },
  address: {
    flexDirection: "column",
    display: "flex",
    marginBottom: 5,
    color: "#3d5170",
    fontSize: 12,
    minWidth: 30,
    flexWrap: "wrap",
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  tableTitle: {
    fontSize: 10,
    flexDirection: "column",
    display: "flex",
    minWidth: 90,
    marginBottom: 5,
    color: "#8493AB",
  },
  tableText: {
    flexDirection: "column",
    display: "flex",
    minWidth: 90,
    marginBottom: 5,
    color: "#3d5170",
    fontSize: 11,
  },
  information: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  remarkBox: {
    marginTop: 10,
    width: "auto",
    borderWidth: 1,
    borderColor: "#8493AB",
    borderRadius: 10,
    padding: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: "column",
    flexGrow: 1,
  },
  products: {
    flexDirection: "row",
    display: "flex",
    gap: 25,
    marginTop: 10,
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderBottomColor: "#3d5170",
  },
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 20,
    display: "flex",
    gap: 10,
  },
  card: {
    width: "calc(25% - 20px)",
    flexDirection: "column",
    display: "flex",
    gap: 5,
    marginBottom: 25,
  },
  tableHeader: {
    flexDirection: "row",
    display: "flex",
    gap: 10,
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderBottomColor: "#8493AB",
  },
  tableRow: {
    flexDirection: "row",
    display: "flex",
    gap: 10,
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderBottomColor: "#8493AB",
  },
  productsInfo: {
    flexDirection: "column",
    display: "flex",
    marginBottom: 5,
    color: "#3d5170",
    fontSize: 11,
    minWidth: 30,
  },
  signatureContainer: {
    marginTop: 10,
    width: "50%",
    height: 100,
    backgroundColor: "#f7f8fa",
    borderRadius: 10,
    padding: 10,
  },
  signaturePhoto: {
    width: "100%",
  },
  firmaText: {
    padding: 15,
    justifyContent: "center",
    fontSize: 8,
    width: "50%",
    marginBottom: 5,
    color: "#8493AB",
  },
  lastPart: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thickLine: {
    borderTopWidth: 4,
    borderTopColor: "#8493AB",
    marginTop: 20,
    marginBottom: 20,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 20,
    right: 20,
    height: 50,
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    fontSize: 7,
    color: "#3d5170",
    paddingRight: 20,
    paddingLeft: 20,
  },
  nameContainer: {
    position: "absolute",
    bottom: 0,
    left: 10,
    right: 10,
  },
  line: {
    borderBottomWidth: 1,
    borderBottomColor: "#8493AB",
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
  },
  footerTitle: {
    fontSize: 12,
    fontWeight: 1000,
  },
  footerItem: {
    flexGrow: 1,
  },
  thirdItem: {
    flex: 1,
    textAlign: "left",
  },
});

type DocumentationProps = {
  documentation: DocumentationSliceState;
};

const typeMappings: { [key: string]: string } = {
  infestation: "Befall",
  noInfestation: "kein Befall",
  maintenancePerformed: "Pflege",
  unreachableOrMissing: "nicht erreichbar",
  notInspected: "nicht kontrolliert",
};

const deficiencyTypeMappings: { [key: string]: string } = {
  hygienic: "Hygienemangel",
  facility: "Baumangel",
  organizational: "Organisatorischer Mangel",
  other: "Sonstiges",
};

const deficiencySubtypeMappings: { [key: string]: string } = {
  wallHole: "Loch vorhanden",
  jointDefective: "Fuge defekt",
  doorGateSealDefective: "Tür-/Torabschluss defekt",
  wallFloorConnectionInsufficient: "Wand-/Bodenanschluss unzureichend",
  wallFloorCoveringDefective: "Wand-/Bodenbelag defekt",
  fecesTraces: "Kotspuren vorhanden",
  deadAnimals: "Tote Tiere vorhanden",
  productResidues: "Produktreste vorhanden",
  eatenGoods: "Ware angefressen",
  cobwebs: "Gespinste vorhanden",
  pruningRequired: "Grünschnitt erforderlich",
  areaNotAccessible: "Bereich nicht zugänglich",
  doorGateOpen: "Tür/Tor steht offen",
};

const today = moment().format("DD. MMMM YYYY");
const time = moment().format("HH:mm");

const Footer = () => (
  <View style={styles.footer} fixed>
    <View style={styles.footerItem}>
      <Text style={styles.footerTitle}>{config.companyName}</Text>
      <Text style={styles.title}>{config.companyAddress}</Text>
      <Text style={styles.title}>{config.companyCity}</Text>
    </View>

    <View style={styles.footerItem}>
      <Text style={styles.title}>Automatisch erstellt am</Text>
      <Text style={styles.footerTitle}>
        {today} um {time}
      </Text>
    </View>

    <View style={[styles.footerItem, styles.thirdItem]}>
      <Text style={styles.title}>Seite</Text>
      <Text
        style={styles.footerTitle}
        render={({ pageNumber, totalPages }) =>
          `${pageNumber} von ${totalPages}`
        }
        fixed
      />
    </View>
  </View>
);

export const PDFDocumentForm: React.FC<DocumentationProps> = ({
  documentation,
}) => {
  const appointmentType =
    documentation.appointmentSubset.typeOfAppointment === "auftrag"
      ? "Bekämpfung"
      : documentation.appointmentSubset.typeOfAppointment;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.information}>
            <View style={{ flex: 1, flexDirection: "column" }}>
              <Text style={styles.title}>Kunde</Text>
              <Text style={styles.name}>
                {documentation.appointmentSubset.customerName}
              </Text>
              <Text style={styles.address}>
                {documentation.appointmentSubset.address}
              </Text>
            </View>
          </View>

          <Text style={styles.documentName}>Zusammenfassung des Einsatzes</Text>

          <View style={styles.information}>
            <View>
              <Text style={styles.title}>Leistung</Text>
              {appointmentType ? (
                <Text style={styles.text}>
                  {appointmentType.charAt(0).toUpperCase() +
                    appointmentType.slice(1)}
                </Text>
              ) : null}
            </View>

            <View>
              <Text style={styles.title}>Datum</Text>
              <Text style={styles.text}>{documentation.date}</Text>
            </View>

            <View>
              <Text style={styles.title}>Arbeitsaufwand</Text>
              <Text style={styles.text}>{documentation.totalTimeWorkload}</Text>
            </View>

            <View>
              <Text style={styles.title}>Verantwortlicher Techniker</Text>
              {documentation.appointmentSubset.technicians.length > 0
                ? documentation.appointmentSubset.technicians.map(
                    (technician: Technician, index: number) => (
                      <View key={index}>
                        <Text style={styles.text}>
                          {technician.firstName} {technician.lastName}
                        </Text>
                      </View>
                    )
                  )
                : null}
            </View>
          </View>

          <View style={styles.thickLine} />

          <View>
            <Text style={styles.name}>
              Arbeits-, Material- und Präparateeinsatz
            </Text>

            <View>
              <View style={styles.products}>
                <Text style={styles.title}>Menge</Text>
                <Text style={styles.title}>Artikel</Text>
              </View>
              {documentation.products.map((product: MinimalProduct) => (
                <View style={styles.products}>
                  <Text style={styles.productsInfo}>{product.quantity}</Text>
                  <Text style={styles.productsInfo}>{product.name}</Text>
                </View>
              ))}
            </View>

            <View style={styles.thickLine} />
          </View>

          <View break>
            <Text style={styles.name}>Bemerkungsfeld</Text>

            <View style={styles.remarkBox}>
              {documentation.remark ? (
                <Text style={styles.text}>{documentation.remark}</Text>
              ) : (
                <Text style={styles.title}>Kein bemerkungsfeld</Text>
              )}
            </View>
          </View>
        </View>
        <Footer />
      </Page>
      <Page size="A4" wrap={true} style={styles.normalPage}>
        <View style={styles.section}>
          {documentation.propertyAreas.length > 0
            ? documentation.propertyAreas.map(
                (area: PropertyArea, index: number) => (
                  <View key={index}>
                    <Text style={styles.name}>{area.area}</Text>

                    <Text style={styles.text}>Befunde</Text>

                    <View style={styles.cardContainer}>
                      {area.events.map((event: any, i: any) => (
                        <View style={styles.card} key={i}>
                          <Text style={styles.text}>{event.monitorType}</Text>
                          <View style={styles.tableHeader}>
                            <Text style={styles.tableTitle}>
                              Monitor-Status
                            </Text>
                            <Text style={styles.tableTitle}>Falle</Text>
                          </View>
                          {Object.entries(typeMappings).map(
                            ([eventType, mapping], index) => {
                              const filteredEvents = event.events.filter(
                                (subEvent: any) =>
                                  subEvent.type === eventType &&
                                  subEvent.count !== null
                              );

                              if (filteredEvents.length > 0) {
                                return (
                                  <View style={styles.tableRow} key={index}>
                                    <Text style={styles.tableText}>
                                      {mapping}
                                    </Text>
                                    <Text style={styles.text}>
                                      {filteredEvents.map(
                                        (subEvent: any) => subEvent.count
                                      )}
                                    </Text>
                                  </View>
                                );
                              } else {
                                return null;
                              }
                            }
                          )}
                        </View>
                      ))}
                    </View>

                    <Text style={styles.text}>Monitore</Text>
                    <View style={styles.tableHeader}>
                      <Text style={styles.tableTitle}>Monitorbezeichnung</Text>
                      <Text style={styles.tableTitle}>Aktion</Text>
                      <Text style={styles.tableTitle}>Neue Bezeichnung</Text>
                      <Text style={styles.tableTitle}>Neue Position</Text>
                      <Text style={styles.tableTitle}>Grund</Text>
                    </View>

                    {documentation.propertyAreaAdjustedMonitors.length > 0 &&
                      documentation.propertyAreaAdjustedMonitors.map(
                        (
                          adjustedMonitors: PropertyAreaAdjustedMonitors,
                          k: number
                        ) => (
                          <View key={k}>
                            {adjustedMonitors.label === area.area &&
                            adjustedMonitors.monitors.length > 0 ? (
                              adjustedMonitors.monitors.map(
                                (monitor: any, h: number) => {
                                  const originalVersion = monitor.versions.find(
                                    (version: any) => version.isOriginal === 1
                                  );

                                  const changedVersion = monitor.versions.find(
                                    (version: any) => version.isOriginal === 0
                                  );

                                  return (
                                    <View style={styles.tableRow} key={h}>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "15%" },
                                        ]}
                                      >
                                        {`${monitor.monitorType.prefix.toUpperCase()} ${
                                          originalVersion
                                            ? originalVersion.position
                                            : monitor.position
                                        }`}
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "15%" },
                                        ]}
                                      >
                                        Position angepasst
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "15%" },
                                        ]}
                                      >
                                        {`${monitor.monitorType.prefix.toUpperCase()} ${
                                          monitor.position
                                        }`}
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "15%" },
                                        ]}
                                      >
                                        {monitor.location
                                          ? monitor.location
                                          : "-"}
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "40%" },
                                        ]}
                                      >
                                        {changedVersion.reason
                                          ? changedVersion.reason
                                          : "-"}
                                      </Text>
                                    </View>
                                  );
                                }
                              )
                            ) : (
                              <View>
                                <View style={styles.tableRow}>
                                  <Text
                                    style={[styles.tableText, { width: "15%" }]}
                                  >
                                    -
                                  </Text>
                                  <Text
                                    style={[styles.tableText, { width: "15%" }]}
                                  >
                                    -
                                  </Text>
                                  <Text
                                    style={[styles.tableText, { width: "15%" }]}
                                  >
                                    -
                                  </Text>
                                  <Text
                                    style={[styles.tableText, { width: "40%" }]}
                                  >
                                    -
                                  </Text>
                                </View>
                              </View>
                            )}
                          </View>
                        )
                      )}

                    <Text style={styles.text}>Mängel</Text>
                    <View style={styles.tableHeader}>
                      <Text style={[styles.tableTitle, { width: "25%" }]}>
                        Art des Mangels
                      </Text>
                      <Text style={[styles.tableTitle, { width: "25%" }]}>
                        Bezeichnung
                      </Text>
                      <Text style={[styles.tableTitle, { width: "25%" }]}>
                        Standortinformation
                      </Text>
                      <Text style={[styles.tableTitle, { width: "25%" }]}>
                        Anmerkung
                      </Text>
                    </View>
                    {documentation.propertyAreasDeficiencies.length > 0 &&
                      documentation.propertyAreasDeficiencies.map(
                        (deficiency: PropertyAreaDeficiencies, k: number) => (
                          <View key={k}>
                            {deficiency.area === area.area &&
                              (deficiency.incidents.length > 0 ? (
                                deficiency.incidents.map(
                                  (incident: Deficiency, h: number) => (
                                    <View style={styles.tableRow} key={h}>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "25%" },
                                        ]}
                                      >
                                        {
                                          deficiencyTypeMappings[
                                            incident.deficiencyType
                                          ]
                                        }
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "25%" },
                                        ]}
                                      >
                                        {
                                          deficiencySubtypeMappings[
                                            incident.deficiencySubType
                                          ]
                                        }
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "25%" },
                                        ]}
                                      >
                                        {incident.location
                                          ? incident.location
                                          : "-"}
                                      </Text>
                                      <Text
                                        style={[
                                          styles.tableText,
                                          { width: "25%" },
                                        ]}
                                      >
                                        {incident.comment
                                          ? incident.comment
                                          : "-"}
                                      </Text>
                                    </View>
                                  )
                                )
                              ) : (
                                <View>
                                  <View style={styles.tableRow}>
                                    <Text
                                      style={[
                                        styles.tableText,
                                        { width: "25%" },
                                      ]}
                                    >
                                      -
                                    </Text>
                                    <Text
                                      style={[
                                        styles.tableText,
                                        { width: "25%" },
                                      ]}
                                    >
                                      -
                                    </Text>
                                    <Text
                                      style={[
                                        styles.tableText,
                                        { width: "25%" },
                                      ]}
                                    >
                                      -
                                    </Text>
                                    <Text
                                      style={[
                                        styles.tableText,
                                        { width: "25%" },
                                      ]}
                                    >
                                      -
                                    </Text>
                                  </View>
                                </View>
                              ))}
                          </View>
                        )
                      )}
                  </View>
                )
              )
            : null}

          <View style={styles.thickLine} />

          {documentation.propertyAreasDeficiencies
            .filter((deficiency: PropertyAreaDeficiencies) =>
              documentation.propertyAreas.some(
                (area: PropertyArea) => area.area !== deficiency.area
              )
            )
            .map((deficiency: PropertyAreaDeficiencies, k: number) => (
              <View key={k}>
                <Text style={styles.name}>{deficiency.area}</Text>
                <Text style={styles.text}>Mängel</Text>
                <View style={styles.tableHeader}>
                  <Text style={[styles.tableTitle, { width: "25%" }]}>
                    Art des Mangels
                  </Text>
                  <Text style={[styles.tableTitle, { width: "25%" }]}>
                    Bezeichnung
                  </Text>
                  <Text style={[styles.tableTitle, { width: "25%" }]}>
                    Standortinformation
                  </Text>
                  <Text style={[styles.tableTitle, { width: "25%" }]}>
                    Anmerkung
                  </Text>
                </View>
                {deficiency.incidents.length > 0 ? (
                  deficiency.incidents.map(
                    (incident: Deficiency, h: number) => (
                      <View style={styles.tableRow} key={h}>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          {deficiencyTypeMappings[incident.deficiencyType]}
                        </Text>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          {
                            deficiencySubtypeMappings[
                              incident.deficiencySubType
                            ]
                          }
                        </Text>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          {incident.location ? incident.location : "-"}
                        </Text>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          {incident.comment ? incident.comment : "-"}
                        </Text>
                      </View>
                    )
                  )
                ) : (
                  <View>
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableText, { width: "25%" }]}>
                        -
                      </Text>
                      <Text style={[styles.tableText, { width: "25%" }]}>
                        -
                      </Text>
                      <Text style={[styles.tableText, { width: "25%" }]}>
                        -
                      </Text>
                      <Text style={[styles.tableText, { width: "25%" }]}>
                        -
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            ))}

          {documentation.propertyAreas.length === 0 &&
            documentation.propertyAreasDeficiencies.map(
              (deficiency: PropertyAreaDeficiencies, k: number) => (
                <View key={k}>
                  <Text style={styles.name}>{deficiency.area}</Text>
                  <Text style={styles.text}>Mängel</Text>
                  <View style={styles.tableHeader}>
                    <Text style={[styles.tableTitle, { width: "25%" }]}>
                      Art des Mangels
                    </Text>
                    <Text style={[styles.tableTitle, { width: "25%" }]}>
                      Bezeichnung
                    </Text>
                    <Text style={[styles.tableTitle, { width: "25%" }]}>
                      Standortinformation
                    </Text>
                    <Text style={[styles.tableTitle, { width: "25%" }]}>
                      Anmerkung
                    </Text>
                  </View>
                  {deficiency.incidents.length > 0 ? (
                    deficiency.incidents.map(
                      (incident: Deficiency, h: number) => (
                        <View style={styles.tableRow} key={h}>
                          <Text style={[styles.tableText, { width: "25%" }]}>
                            {deficiencyTypeMappings[incident.deficiencyType]}
                          </Text>
                          <Text style={[styles.tableText, { width: "25%" }]}>
                            {
                              deficiencySubtypeMappings[
                                incident.deficiencySubType
                              ]
                            }
                          </Text>
                          <Text style={[styles.tableText, { width: "25%" }]}>
                            {incident.location ? incident.location : "-"}
                          </Text>
                          <Text style={[styles.tableText, { width: "25%" }]}>
                            {incident.comment ? incident.comment : "-"}
                          </Text>
                        </View>
                      )
                    )
                  ) : (
                    <View>
                      <View style={styles.tableRow}>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          -
                        </Text>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          -
                        </Text>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          -
                        </Text>
                        <Text style={[styles.tableText, { width: "25%" }]}>
                          -
                        </Text>
                      </View>
                    </View>
                  )}
                </View>
              )
            )}

          <View style={styles.thickLine} />

          <View break={true}>
            <Text style={styles.name}>Unterschrift</Text>
            <View style={styles.lastPart}>
              <View style={styles.signatureContainer}>
                {documentation.signature.url && (
                  <Image
                    src={documentation.signature.url}
                    style={styles.signaturePhoto}
                  />
                )}
                <View style={styles.nameContainer}>
                  <View style={styles.line} />

                  <Text style={styles.title}>{documentation.emailOwner}</Text>
                </View>
              </View>

              <Text style={styles.firmaText}>
                Die Firma F+M Schädlingsbekämpfungs GmbH ist durch den o. g.
                Auftraggeber beauftragt, in o. g. Lieferanschrift die
                dokumentierten Arbeiten durchzuführen und/oder Präparate
                einzusetzen. Achtung! Über Gefährdungen- Sicherheits- und
                Reinigungsmaßnahmen, sowie die allgemeinen Hinweise wurde der
                Auftraggeber vor Beginn der Maßnahme aufgeklärt.
              </Text>
            </View>
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};
