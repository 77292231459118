import {
  AccessTime,
  Analytics,
  CreateNewFolder,
  Dashboard,
  EventNote,
  Settings,
} from "@mui/icons-material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { useScreenSize } from "../../hooks/useScreenSize";
import { DefectIcon } from "../../icons/DefectIcon/DefectIcon";
import { MonitorSmileIcon } from "../../icons/MonitorSmileIcon/MonitorSmileIcon";
import { RootState } from "../../store";
import { servicedMonitorsActions } from "../../store/serviced-monitors";
import { activeTabsActions } from "../../store/tabs-slice";

import Logo from "./desktop-logo.png";
import styles from "./navigation.module.css";

type NavigationLink = {
  to: string;
  component: JSX.Element;
  label: string;
};

const NAV_LINKS: NavigationLink[] = [
  { to: "/", component: <Dashboard />, label: "LastServices" },
  { to: "/time", component: <AccessTime />, label: "Time" },
  {
    to: "/customerinsight",
    component: <CreateNewFolder />,
    label: "EnterTask",
  },
  { to: "/calendar", component: <EventNote />, label: "Absences" },
  { to: "/settings", component: <Settings />, label: "Settings" },
];

const CUSTOMER_NAV_LINKS: NavigationLink[] = [
  { to: "/", component: <Dashboard />, label: "LastServices" },
  {
    to: "/deficiencies",
    component: <DefectIcon />,
    label: "Defects & Infestation",
  },
  { to: "/monitors", component: <MonitorSmileIcon />, label: "Monitore" },
  { to: "/analysis", component: <Analytics />, label: "Analysis" },
  { to: "/settings", component: <Settings />, label: "Settings" },
];

export const Navigation = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop } = useScreenSize();
  const isCustomerAdmin = useSelector(
    (state: RootState) => state.user.isCustomerAdmin
  );

  const currentPage = useSelector(
    (state: RootState) => state.tabs.dashboard.url
  );

  const [onDashboard, setOnDashboard] = useState(currentPage === "/");

  const handleDashboardNavigation = () => {
    if (!onDashboard) {
      navigate(currentPage);

      dispatch(activeTabsActions.setMonitorsStep(1));
      dispatch(servicedMonitorsActions.resetState());
      dispatch(activeTabsActions.setDashbaordUrl("/"));
    } else {
      navigate(currentPage);
      dispatch(activeTabsActions.setDashbaordUrl("/"));

      navigate("/");

      setOnDashboard(true);
    }
  };

  const getNavLinkClassName = ({ isActive, isPending }: any) =>
    isPending ? styles.pending : isActive ? styles.active : "";

  const LINKS = isCustomerAdmin ? CUSTOMER_NAV_LINKS : NAV_LINKS;

  return (
    <div className={styles["dashboard-navigation"]}>
      {isDesktop && (
        <img src={Logo} className={styles["dashboard-navigation__logo"]} />
      )}
      <div className={styles["dashboard-navigation-links"]}>
        {LINKS.map(({ to, component, label }, i) => {
          if (to === "/") {
            return (
              <button
                key={i}
                className={`${styles.navLinkButton} ${getNavLinkClassName}`}
                onClick={handleDashboardNavigation}
              >
                {component}
                {isDesktop && (
                  <label
                    className={styles["dashboard-navigation-links__label"]}
                  >
                    {t(label)}
                  </label>
                )}
              </button>
            );
          }

          return (
            <NavLink key={i} to={to} className={getNavLinkClassName}>
              {component}
              {isDesktop && (
                <label className={styles["dashboard-navigation-links__label"]}>
                  {t(label)}
                </label>
              )}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};
