import { CRS, Map } from "leaflet";
import { useEffect, useRef, useState } from "react";
import { MapContainer } from "react-leaflet";

import { Document } from "../../types/document";
import { Crop, MonitorPinAbbr } from "../../types/map";

import MapContent from "./MapContent";
import styles from "./pin-crop-map.module.css";

interface Prop {
  map: Document;
  crop: Crop | Crop[];
  monitorPinAbbr: MonitorPinAbbr | MonitorPinAbbr[];
  isDraggable?: boolean;
  zoom?: boolean;
  onCropChange: (crop: Crop) => void;
}

const PinCropMap = (prop: Prop) => {
  const { map, crop, monitorPinAbbr, isDraggable, zoom, onCropChange } = prop;
  const mapContainerRef = useRef<Map | null>(null);
  const [zoomLevel, setZoomLevel] = useState(-1);

  const imageHeight = map?.imageHeight || 250;
  const imageWidth = map?.imageWidth || 250;

  const calculateZoomLevel = () => {
    if (!mapContainerRef.current) return;

    const container = mapContainerRef.current.getContainer();
    const { width, height } = container?.getBoundingClientRect();

    const imageAspectRatio = imageWidth / imageHeight;
    const containerAspectRatio = width / height;

    let zoomLevel = -1; // Default zoom level

    if (containerAspectRatio > imageAspectRatio) {
      zoomLevel = Math.log2(height / imageHeight);
    } else {
      zoomLevel = Math.log2(width / imageWidth);
    }

    setZoomLevel(Math.floor(zoomLevel));
  };

  useEffect(() => {
    if (Array.isArray(crop)) return;

    if (crop.x === 1 && crop.y === 1) {
      const newCrop = { x: imageWidth / 2, y: imageHeight / 2 };
      onCropChange(newCrop);
    }
  }, [crop, imageWidth, imageHeight, onCropChange]);

  return (
    <MapContainer
      center={[imageHeight / 2, imageWidth / 2]}
      crs={CRS.Simple}
      minZoom={zoomLevel}
      zoom={zoomLevel}
      maxBoundsViscosity={1.0}
      scrollWheelZoom={zoom ?? true}
      touchZoom={zoom ?? true}
      zoomControl={zoom ?? true}
      dragging={isDraggable ?? true}
      className={styles["map-container"]}
      ref={mapContainerRef}
      whenReady={() => {
        setTimeout(() => {
          calculateZoomLevel();
        }, 0);
      }}
    >
      {crop && monitorPinAbbr && (
        <MapContent
          imageHeight={imageHeight}
          imageWidth={imageWidth}
          propertyMap={map}
          monitorPinAbbr={monitorPinAbbr}
          crop={crop}
          onCropChange={onCropChange}
          isDraggable={isDraggable}
          zoomLevel={zoomLevel}
        />
      )}
    </MapContainer>
  );
};

export default PinCropMap;
